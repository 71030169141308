import { lazy } from 'react'

import Loadable from 'components/Loadable'
import { profilesAccepted, profilesAdministrative } from 'config'
import CommonLayout from 'layout/CommonLayout'
import MainLayout from 'layout/MainLayout'
import { ProtectedRoute } from 'utils/role/ProtectedRoute'
import AuthGuard from 'utils/route-guard/AuthGuard'

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')))
const MaintenanceUnauthorized = Loadable(lazy(() => import('pages/maintenance/401')))
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')))
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')))
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')))

const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/Account')))
const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')))

const Configuration = Loadable(lazy(() => import('pages/apps/configuration/Configuration')))

const Home = Loadable(lazy(() => import('pages/apps/home/Home')))
const Customer = Loadable(lazy(() => import('pages/apps/customer/Customer')))
const CustomerEdit = Loadable(lazy(() => import('sections/apps/customer/CustomerEditMain')))
const CustomerProcessNew = Loadable(lazy(() => import('sections/apps/customer/CustomerProcessNewMain')))
const CustomerProcessEdit = Loadable(lazy(() => import('sections/apps/customer/CustomerProcessEditMain')))
const Bizagi = Loadable(lazy(() => import('pages/apps/bizagi/Bizagi')))
const Planning = Loadable(lazy(() => import('pages/apps/planning/Planning')))
const TimeRegistrationMain = Loadable(lazy(() => import('pages/apps/time-registration/TimeRegistrationMain')))
const Report = Loadable(lazy(() => import('pages/apps/report/Report')))

const Project = Loadable(lazy(() => import('pages/apps/project/Project')))
const FormNewProject = Loadable(lazy(() => import('sections/apps/project/form-project/FormNewProject')))
const FormEditProject = Loadable(lazy(() => import('sections/apps/project/form-project/FormEditProject')))

const ServicesActivities = Loadable(lazy(() => import('pages/apps/services-activities/ServicesActivities')))
const ServicesActivitiesEdit = Loadable(lazy(() => import('sections/apps/services-activities/ServicesActivitiesEdit')))
const ServicesActivitiesNew = Loadable(lazy(() => import('sections/apps/services-activities/ServicesActivitiesNew')))

const Employee = Loadable(lazy(() => import('pages/apps/employee/Employee')))
const EmployeeEdit = Loadable(lazy(() => import('sections/apps/employee/EmployeeEdit')))
const EmployeeNew = Loadable(lazy(() => import('sections/apps/employee/EmployeeNew')))

const PlanningEdit = Loadable(lazy(() => import('pages/apps/planning/PlanningEdit')))
const TabPlanes = Loadable(lazy(() => import('sections/apps/planning/edit/TabPlanes')))
const TabHonorarios = Loadable(lazy(() => import('sections/apps/planning/edit/TabHonorarios')))
const TabGastos = Loadable(lazy(() => import('sections/apps/planning/edit/TabGastos')))

const ReportCustomer = Loadable(lazy(() => import('pages/apps/reportCustomer/Clientes')))
const DIPendientes = Loadable(lazy(() => import('pages/apps/reportCustomer/DIPendientes')))
const ProjectosAprobaciones = Loadable(lazy(() => import('pages/apps/reportCustomer/ProyectosAprobaciones')))
const EnvironmentProject = Loadable(lazy(() => import('pages/apps/environmentProject/environmentProject')))

const ComplianceIRC = Loadable(lazy(() => import('pages/apps/compliance-irc/Compliance-IRC')))

const SolicitudFacturacionPage = Loadable(lazy(() => import('pages/apps/solicitud-facturacion/solicitud-facturacion')));

export const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'home',
          element: <Home />
        },
        {
          path: 'time-registration',
          element: <TimeRegistrationMain />
        },
        {
          path: 'customer',
          children: [
            {
              path: 'list',
              element: <Customer />
            },
            {
              path: 'process/new',
              element: (
                <ProtectedRoute roles={profilesAccepted}>
                  <CustomerProcessNew />
                </ProtectedRoute>
              )
            },
            {
              path: 'edit/:id',
              element: (
                <ProtectedRoute roles={profilesAccepted}>
                  <CustomerEdit />
                </ProtectedRoute>
              )
            },
            {
              path: 'process/:id',
              element: (
                <ProtectedRoute roles={profilesAccepted}>
                  <CustomerProcessEdit />
                </ProtectedRoute>
              )
            }
          ]
        },
        {
          path: 'project',
          children: [
            {
              path: 'list',
              element: <Project />
            },
            {
              path: 'new-project',
              element: <FormNewProject />
            },
            {
              path: 'edit-project/:codigo',
              element: <FormEditProject />
            }
          ]
        },
        {
          path: 'inbox',
          children: [
            {
              path: 'clientes',
              element: (
                <ProtectedRoute roles={profilesAccepted}>
                  <ReportCustomer />
                </ProtectedRoute>
              )
            },
            {
              path: 'di-pending',
              element: (
                <ProtectedRoute roles={profilesAccepted}>
                  <DIPendientes />
                </ProtectedRoute>
              )
            },
            {
              path: 'project-approbation',
              element: (
                <ProtectedRoute roles={profilesAccepted}>
                  <ProjectosAprobaciones />
                </ProtectedRoute>
              )
            }
          ]
        },
        {
          path: 'environment-project',
          element: (
            <ProtectedRoute roles={profilesAccepted}>
              <EnvironmentProject />
            </ProtectedRoute>
          )
        },
        {
          path: 'compliance-irc',
          element: (
            <ProtectedRoute roles={profilesAdministrative}>
              <ComplianceIRC />
            </ProtectedRoute>
          )
        },
        {
          path: 'reports',
          element: <Report />
        },
        {
          path: 'employee',
          children: [
            {
              path: 'list',
              element: (
                <ProtectedRoute roles={profilesAdministrative}>
                  <Employee />
                </ProtectedRoute>
              )
            },
            {
              path: 'edit/:id',
              element: (
                <ProtectedRoute roles={profilesAdministrative}>
                  <EmployeeEdit />
                </ProtectedRoute>
              )
            },
            {
              path: 'new',
              element: (
                <ProtectedRoute roles={profilesAdministrative}>
                  <EmployeeNew />
                </ProtectedRoute>
              )
            }
          ]
        },
        {
          path: 'bizagi',
          element: (
            <ProtectedRoute roles={profilesAccepted}>
              <Bizagi />
            </ProtectedRoute>
          )
        },
        {
          path: 'planning',
          children: [
            {
              path: 'list',
              element: (
                <ProtectedRoute roles={profilesAccepted}>
                  <Planning />
                </ProtectedRoute>
              )
            },
            {
              path: 'edit/:id',
              element: (
                <ProtectedRoute roles={profilesAccepted}>
                  <PlanningEdit />
                </ProtectedRoute>
              ),
              children: [
                {
                  path: 'planes',
                  element: (
                    <ProtectedRoute roles={profilesAccepted}>
                      <TabPlanes />
                    </ProtectedRoute>
                  )
                },
                {
                  path: 'honorarios',
                  element: (
                    <ProtectedRoute roles={profilesAccepted}>
                      <TabHonorarios />
                    </ProtectedRoute>
                  )
                },
                {
                  path: 'gastos',
                  element: (
                    <ProtectedRoute roles={profilesAccepted}>
                      <TabGastos />
                    </ProtectedRoute>
                  )
                }
              ]
            }
          ]
        },
        {
          path: 'account',
          element: <AccountProfile />,
          children: [
            {
              path: 'basic',
              element: <AccountTabProfile />
            }
          ]
        },
        {
          path: 'configuration',
          element: (
            <ProtectedRoute roles={profilesAdministrative}>
              <Configuration />
            </ProtectedRoute>
          )
        },
        {
          path: 'services-activities',
          children: [
            {
              path: 'list',
              element: (
                <ProtectedRoute roles={profilesAdministrative}>
                  <ServicesActivities />
                </ProtectedRoute>
              )
            },
            {
              path: 'edit/:id',
              element: (
                <ProtectedRoute roles={profilesAdministrative}>
                  <ServicesActivitiesEdit />
                </ProtectedRoute>
              )
            },
            {
              path: 'new',
              element: (
                <ProtectedRoute roles={profilesAdministrative}>
                  <ServicesActivitiesNew />
                </ProtectedRoute>
              )
            },
            {
              path: 'solicitud-facturacion',
              element: (
                <ProtectedRoute roles={profilesAccepted}>
                  <SolicitudFacturacionPage />
                </ProtectedRoute>)
            }

          ]
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '401',
          element: <MaintenanceUnauthorized />
        },
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
}

export const routesNoMatch = {
  path: '*',
  element: <MaintenanceError />
}
